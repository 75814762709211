import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { Routing } from "./constants/routing";

/* Import lazy routes */
const MainLayout = lazy(() => import("../layouts/MainLayout"));
const JSONFormator = lazy(() => import("../pages/JSONFormator/JSONFormator"));
const XMLFormatter = lazy(() => import("../pages/XMLFormatter/XMLFormatter"));
interface IRoute {
  path: string;
  component: any;
  isPrivateRoute: boolean;
}

const routes: IRoute[] = [
  // {
  //   path: Routing.JSONFormator,
  //   component: <JSONFormator />,
  //   isPrivateRoute: false,
  // },
  {
    path: Routing.JSONViewer,
    component: <JSONFormator />,
    isPrivateRoute: false,
  },
  {
    path: Routing.JSONViewer1,
    component: <JSONFormator />,
    isPrivateRoute: false,
  },
  {
    path: Routing.XMLFormattor,
    component: <XMLFormatter />,
    isPrivateRoute: false,
  },
];

export const AppRouting: React.FC = () => {
  return (
    <Router>
      <MainLayout>
        <Switch>
          {routes
            .filter((route) => !route.isPrivateRoute)
            .map(({ path, component }, key) => (
              <Route exact path={path} component={() => component} key={key} />
            ))}
          {/* Redirect for unmatched routes */}
          <Redirect to={Routing.JSONViewer1} />
        </Switch>
      </MainLayout>
    </Router>
  );
};
